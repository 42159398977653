.input-group-addon {
  padding: 6px 10px;
  font-size: 21px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.nav-tabs-custom {
  border-bottom: 2px solid #f6f6f6;
}

.nav-tabs a {
  color: #495057 !important;
  font-weight: 500 !important;
  position: relative !important;
  border: none !important;
}

.nav-tabs-custom.nav-tabs .nav-item.show .nav-link,
.nav-tabs-custom.nav-tabs .nav-link.active {
  color: #556ee6 !important;
  border: none !important;
}

.nav-tabs-custom.nav-tabs .nav-item.show .nav-link,
.nav-tabs-custom.nav-tabs .nav-link.active:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  content: "";
  background: #556ee6;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  -webkit-transition: all 250ms ease 0s;
  transition: all 250ms ease 0s;
}

.document-img img {
  max-width: 50px;
  height: auto;
}

.led-green {
  width: 12px;
  height: 12px;
  background-color: #a1ee02;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #8cff07 0 0px 9px;
  margin: 9px;
}

.led-red {
  width: 12px;
  height: 12px;
  background-color: #ee0202;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #ff0707 0 0px 9px;
  margin: 9px;
}

.led-yellow {
  width: 12px;
  height: 12px;
  background-color: #eee702;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #fbff07 0 0px 9px;
  margin: 9px;
}

.led-gray {
  width: 12px;
  height: 12px;
  background-color: #e7e7e7;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #fbfced 0 0px 9px;
  margin: 9px;
}

.led-orange {
  width: 12px;
  height: 12px;
  background-color: #ffa500;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #fbfced 0 0px 9px;
  margin: 9px;
}

.custom-table .rdt_TableRow {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: unset;
  overflow-y: unset;
  min-height: 0;
}

.dropdown-menu.show {
  right: 0 !important;
  left: unset !important;
}

.rdt_TableCell div:first-child {
  overflow: unset !important;
}

.dropdown-menu li {
  color: #070606;
  font-size: 13px;
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid #8d8d8d;
}

.dropdown-menu li i {
  margin-right: 5px;
  color: #556ee6;
}

.profile-img {
  height: 40px;
  width: 40px;
  display: block;
  border-radius: 50%;
  overflow: hidden !important;
  margin: 8px 0;
}

.profile-img img {
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.documentImgSection {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.documentImgSection .docImg {
  border-radius: 6px;
  border: 1px solid #e7e7e7;
  padding: 10px;
  margin: 0 10px;
}

.documentImgSection .docImg img {
  max-height: 180px;
  width: auto;
}

.gameSessionLogs {
  max-height: 300px;
  overflow-y: auto;
}

.gameSessionLogs .table thead {
  position: sticky;
  top: -2px;
  height: 30px;
  background-color: #ffffff !important;
}

.playerDetailProfile {
  max-width: 100%;
  height: auto;
}

li>p {
  margin-top: 0;
  margin-bottom: 0;
}


.badge-nu {
  width: 20px;
  height: 20px;
  background-color: red;
  color: black;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-column-action {
  overflow: unset !important;
}

.custom-data-table {
  position: relative !important;
  width: 100%;
  /* overflow-x:unset !important;
    overflow-y: unset !important; */
  overflow: auto auto !important;
  min-height: 320px !important;
}

.custom_dropdown {
  position: absolute;
  left: 28px;
  top: 20px;
  z-index: 1;
  width: 260px;
}

@media (max-width: 1199px) {
  .custom-data-table {
    position: relative !important;
    width: 100%;
    overflow-x: auto !important;
    overflow-y: unset !important;
    min-height: 320px !important;
  }

  .custom-data-table .dropdown-menu li {
    font-size: 12px;
    padding: 5px 5px;
  }
}

@media (max-width: 1199px) {
  .custom-data-table {
    position: relative !important;
    width: 100%;
    overflow-x: auto !important;
    overflow-y: unset !important;
    min-height: 320px !important;
  }

  .custom-data-table .dropdown-menu li {
    font-size: 12px;
    padding: 5px 5px;
  }
}

@media (max-width: 575px) {
  .custom-table-header {
    width: 100%;
    padding-top: 75px;
  }

  .paginationSearchInput {
    left: 0;
    right: 0;
    top: 45px;
  }
}


.switch .btn-toggle {
  top: 50%;
  transform: translateY(-50%);
}

.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle.active {
  background-color: #556ee6;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle:before {
  content: 'Off';
  left: -4rem;
}

.btn-toggle:after {
  content: 'On';
  right: -4rem;
  opacity: 0.5;
}

.btn-toggle>.handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.active {
  transition: background-color 0.25s;
}

.btn-toggle.active>.handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.active:before {
  opacity: 0.5;
}

.btn-toggle.active:after {
  opacity: 1;
}

.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}

.btn-toggle.btn-sm:before {
  text-align: right;
}

.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-sm.active:before {
  opacity: 0;
}

.btn-toggle.btn-sm.active:after {
  opacity: 1;
}




.btn-toggle.btn-sm {
  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
}

.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
  outline: none;
}

.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: 1.5rem;
  width: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.55rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle.btn-sm:before {
  content: 'Off';
  left: -0.5rem;
}

.btn-toggle.btn-sm:after {
  content: 'On';
  right: -0.5rem;
  opacity: 0.5;
}

.btn-toggle.btn-sm>.handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.btn-sm.active {
  transition: background-color 0.25s;
}

.btn-toggle.btn-sm.active>.handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.btn-sm.active:before {
  opacity: 0.5;
}

.btn-toggle.btn-sm.active:after {
  opacity: 1;
}

.btn-toggle.btn-sm.btn-sm:before,
.btn-toggle.btn-sm.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}

.btn-toggle.btn-sm.btn-sm:before {
  text-align: right;
}

.btn-toggle.btn-sm.btn-sm:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-sm.btn-sm.active:before {
  opacity: 0;
}

.btn-toggle.btn-sm.btn-sm.active:after {
  opacity: 1;
}

.btn-toggle.btn-sm.btn-xs:before,
.btn-toggle.btn-sm.btn-xs:after {
  display: none;
}

.btn-toggle.btn-xs {
  margin: 0 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1rem;
  width: 2rem;
  border-radius: 1rem;
}

.deletedBtn {
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: 4;
}

.deletedBtn label {
  margin-bottom: 0;
}

.deletedBtn input {
  margin-right: 10px;
}

.agentSearchInput {
  margin-right: 115px;
  margin-bottom: 6px;
}

.rdrDefinedRangesWrapper {
  display: none;
  z-index: 5;
  box-shadow: 0px 4px 15px 0px #00000070;
}

.customDatePiker {
  position: absolute;
  top: 136px;
  left: auto;
  right: 0px;
}

.customDatePikerCount {
  position: absolute;
  /* top: 136px; */
  left: auto;
  right: 10px;
}

.customDatePikerCount .rdrCalendarWrapper {
  z-index: 5;
  color: #000000;
  font-size: 12px;
  box-shadow: 0px 4px 15px 0px #00000070;
}

.customDatePiker .rdrCalendarWrapper {
  z-index: 5;
  color: #000000;
  font-size: 12px;
  box-shadow: 0px 4px 15px 0px #00000070;
}

.lineChartCstmCss {
  min-height: 250px;
}

::-webkit-scrollbar {
  height: 0.3em;
  width: 0.3em;
}

::-webkit-scrollbar-thumb {
  background-color: #646363;
}

.SubmitBtn {
  position: absolute;
  z-index: 6;
  bottom: 4px;
  right: 7px;
  padding: 2px 10px;
  border: none;
  border-radius: 4px;
}

.customSelect {
  width: 160px;
  /* Example width */
}

.cstmPlayerCount {
  min-height: 300px;
  max-height: 800px;
  overflow-y: auto;
}
.apexcharts-menu{
  min-width: 114px !important;
}
.item-card{
  border: 1px solid hsl(0, 0%, 80%);
  padding: 13px 5px;
  border-radius: 4px;
}
.wheel-scroll{
  max-height: 407px;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: auto;
  height: auto;
}
.itemList{border: 1px solid rgba(0, 0, 0, 0.12);
border-top: 0;}
.itemImg{ width: 50px;
  height: 50px;
  padding: 7px;
  object-fit: cover;
}
  .itemImg img{ width: 100%;}
  .itemList:last-child{ border-bottom: 0;}  

  .privatePublicType{
     border: 1px;
     padding: 4px 8px;
     border-radius: 5px;
  }
.modal-min-width .modal-lg{
  min-width: 660px !important;
}
.export-excel{
  position: absolute;
  top: 20px;
  left: 28px;
  z-index: 1;
}
.costomLoader{}
.costomLoader .spinner-border{    border: 0.25em solid #fff;    border-right-color: transparent;}
.rh-prixelist{
  height: 70vh;
}
/* #react-select-5-listbox, #react-select-9-listbox{
  position: relative;
}
.customPosition__option{
  position: relative !important;
} */
.MuiButtonBase-root:hover{
  background-color: transparent;
}
.customModalSize .modal-dialog{max-width: 360px !important;}
.btn-close:hover{ background-color: transparent !important;}
.btn-close:focus{ background-color: transparent !important;}

.costomLoader{}
.costomLoader .spinner-border{    border: 0.25em solid #fff;    border-right-color: transparent;}
.rh-prixelist{
  height: 70vh;
}